import React from 'react';

export const PageSettings = React.createContext();
export const settings = {
    DATE_FORMAT: 'MMM do yyyy',
    FULL_DATE_FORMAT: 'dddd do MMMM yyyy, hh:mm A',
    FORM_DATE_FORMAT: 'DD/MM/YYYY',
    TIME_FORMAT: 'hh:mm a',
    INVOICE_FORMAT: 'MMMM D, YYYY',
    SEARCH_DATE_FORMAT: 'DD-MMM-YYYY'
}

export const AppName = "H.M. Amin Group"
